import "./App.scss";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="title">Shamrocks</h1>
        <h2 className="subtitle">
          Reserve your Twitter username and/or reclaim your verified badge that
          you used to have on Twitter&hellip;
        </h2>
        <span className="claimed">Already claimed?</span>
        <input placeholder="@twitter_username" />
        <button>Claim username</button>
        <span className="s1">
          The username you reserve must be your Twitter username.
        </span>
        <span className="s2">
          You'll validate this in a few moments&hellip;
        </span>
      </header>
      <div className="footer">
        <div className="container">
          <h3>
            The world needs a service similar to what Twitter used to be and we
            will launch within the next few weeks.
          </h3>
          <br/>
          <p>
            The verified blue checkmark was introduced on Twitter in 2009 as a
            badge for public figures, celebrities, journalists, politicians, and
            prominent organizations. This small yet powerful symbol served to
            protect the integrity of these accounts and the information they
            shared, allowing users to trust the content they consumed.
          </p>
          <br/>
          <p>
            By establishing a clear distinction between verified and unverified
            accounts, the Twitter blue checkmark played a vital role in
            maintaining the platform's integrity, fostering trust among users, and
            promoting a safer social media environment for everyone.
          </p>
          <br/>
          <p>
            The importance of reestablishing the blue checkmark on a new
            Twitter-like service is underscored by Twitter's previous role as a
            news-focused platform, where users rely on verified sources to provide
            accurate, timely, and trustworthy information amidst the ever-changing
            landscape of global events.
          </p>
          <br/>
          <p>
            Welcome to <span className="brand">Shamrocks</span>.
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
